.img-texto {
  margin-bottom: 106.24px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
  width: calc(100% - 48px);
  margin-right: auto;
  margin-left: auto;
  max-width: 1232px;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .img-texto {
    margin-bottom: 64px;
    margin-right: 0;
    margin-left: 0;
    width: calc(100% + 0px);
  }
}

.img-texto__cTexto {
  padding-bottom: 56px;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  align-items: center;
  -webkit-box-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  -webkit-box-pack: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .img-texto__cTexto {
    text-align: center;
    padding-right: 24px;
    padding-left: 24px;
    width: calc(100% + 0px);
    max-width: 1232px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.img-texto__cTexto__titulo {
  line-height: 1.1;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  max-width: 616px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .img-texto__cTexto__titulo {
    line-height: 1.125;
    font-size: 1.5rem;
  }
}

.img-texto__row {
  margin: 0 60px;
  align-self: stretch;
  -ms-grid-row-align: stretch;
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 0;
  -webkit-box-flex: 0;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .img-texto__row {
    max-width: 610px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
}

.img-texto__row__contenedorImg {
  flex: 1 1 50%;
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-start;
  -webkit-box-pack: start;
  margin: 0 12px;
  align-items: center;
  -webkit-box-align: center;
  align-self: stretch;
  -ms-grid-row-align: stretch;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-grow: 0;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorImg {
    margin: 0;
  }
}

.img-texto__row__contenedorImg__primero {
  justify-content: flex-end;
  -webkit-box-pack: end;
  flex: 1 1 auto;
  align-self: auto;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorImg__primero {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    justify-content: center;
    -webkit-box-pack: center;
  }
}

.img-texto__row__contenedorImg__primero__segundo {
  max-width: 499.5px;
  margin: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorImg__primero__segundo {
    max-width: 1232.4px;
  }
}

.img-texto__row__contenedorImg__primero__segundo__img {
  padding: 0 0 60% 0;
  overflow: hidden;
  max-height: 100%;
  min-height: 1px;
  height: auto;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorImg__primero__segundo__img {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 75%;
    padding-left: 0px;
  }
}

.img-texto__row__contenedorImg__primero__segundo__img img {
  object-position: center center;
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 1;
  position: absolute;
}

.img-texto__row .alineado-arriba.alineado-arriba {
  align-self: flex-start;
}

@media screen and (max-width: 768px) {
  .img-texto__row .alineado-arriba.alineado-arriba {
    align-self: center;
  }
}

.img-texto__row__contenedorTexto {
  padding: 0;
  margin: 0 40px;
  flex: 1 1 50%;
  align-self: center;
  -ms-grid-row-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-grow: 0;
  -webkit-box-flex: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto {
    padding-top: 40px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 0px;
  }
}

.img-texto__row__contenedorTexto__1 {
  justify-content: flex-start;
  -webkit-box-pack: start;
  flex: 1 1 auto;
  align-self: auto;
  flex-grow: 0;
  -webkit-box-flex: 0;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto__1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    justify-content: center;
    -webkit-box-pack: center;
  }
}

.img-texto__row__contenedorTexto__1__2 {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto__1__2 {
    max-width: 1232.4px;
    margin-top: 0px;
    margin-right: auto;
  }
}

.img-texto__row__contenedorTexto__1__2__3 {
  align-items: flex-start;
  -webkit-box-align: start;
  justify-content: center;
  -webkit-box-pack: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  align-self: stretch;
  -ms-grid-row-align: stretch;
  flex-grow: 0;
  -webkit-box-flex: 0;
}

.img-texto__row__contenedorTexto__1__2__3__titulo {
  line-height: 1.125;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  white-space: pre-line;
  margin: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto__1__2__3__titulo {
    text-align: left;
    line-height: 1.1666666667;
    font-size: 1.5rem;
    margin-top: 0px;
  }
}

.img-texto__row__contenedorTexto__1__2__3 .desactivado.desactivado {
  display: none;
}

.img-texto__row__contenedorTexto__1__2__3__espacio {
  width: 16px;
  align-items: stretch;
  -webkit-box-align: stretch;
  flex-basis: 16px;
  flex-shrink: 0;
  flex-wrap: nowrap;
  -webkit-box-lines: nowrap;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-grow: 0;
  -webkit-box-flex: 0;
}

.img-texto__row__contenedorTexto__1__2__3__texto {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  max-width: 100%;
  white-space: pre-line;
  text-align: justify;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto__1__2__3__texto {
    text-align: left;
    font-size: 1rem;
  }
}

.img-texto__row__contenedorTexto__1__2__3__espacioBtn {
  width: 48px;
  align-items: stretch;
  -webkit-box-align: stretch;
  flex-basis: 48px;
  flex-shrink: 0;
  flex-wrap: nowrap;
  -webkit-box-lines: nowrap;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-grow: 0;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 768px) {
  .img-texto__row__contenedorTexto__1__2__3__espacioBtn {
    width: 40px;
    flex-basis: 40px;
  }
}

.bg-gris {
  padding-top: 50px;
  margin-bottom: 50px;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  background-color: #fafafa;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .bg-gris {
    padding-top: 64px;
    padding-bottom: 64px;
    margin-bottom: 64px;
  }
}

.bg-gris .icono-texto.icono-texto {
  padding-bottom: 0;
}

@media (min-width: 1260px) and (max-height: 720px) {
  .bg-gris {
    max-width: 1150px;
  }
}

.statistics-background {
  background-color: #fafafa;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statistics-container {
  max-width: 1150px;
  margin-top: 20px;
  width: 100%;
}

.font-weight-bold {
  font-weight: 700 !important;
}

/* Estilos para a galeria de imagens */
.image-gallery {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 15px;
}

.slick-slide img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.custom-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0;
  list-style: none;
}

.custom-dot-wrapper {
  width: 60px;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-dot-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.slick-dots .slick-active .custom-dot-wrapper {
  border: 1px solid #2a609d;
  padding: 3px 3px 3px 3px;
  top: -3px;
  position: relative;
  border-radius: 5px;
}

.slick-slide img {
  height: 450px;
  width: 800px;
  display: block;
  margin: 0 auto;
}

.slick-dots {
  bottom: -55px !important;
}

.slick-dots li {
  margin: 0 30px !important;
}

.slidergallery {
  margin-bottom: 150px;
  margin-top: 50px;
}

/* Ajustes para versão mobile */
@media (max-width: 767px) {
  .img-texto__row__contenedorImg__primero__segundo {
    max-width: 350px;
  }

  .img-texto__cTexto {
    padding-bottom: 35px;
  }

  .img-texto__row__contenedorTexto {
    padding-left: 37px;
    padding-right: 50px;
  }

  .img-texto__row__contenedorTexto__1__2__3__texto {
    text-align: justify;
  }

  .bg-gris {
    padding-bottom: 0px;
    margin-top: 40px;
    padding-top: 40px;
  }

  .statistics-container .row {
    flex-direction: column;
  }

  .statistics-container .col-6 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .statistics-background {
    padding: 20px;
  }

  .slick-dots {
    bottom: -100px !important;
    margin-left: -30px;
  }

  .slick-slide img {
    height: 250px;
    width: 400px;
  }
}