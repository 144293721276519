@charset "UTF-8";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0;
    /* font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
    font-family: Volvo Novum, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.bg-main {
  padding: 0;
  width: 100%;
}
.bg-main-menu {
  overflow: hidden;
}

.bg-relative {
  position: relative;
}

.bg-block {
  display: block;
}

.bg-flex {
  display: flex;
}

/* //////////////  Colores  /////////////// */
/* //////////////  Font  /////////////// */
@font-face {
  font-family: "Volvo-Novum-Light";
  src: url("../public/fonts/Volvo-Novum-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Volvo-Novum-Regular";
  src: url("../public/fonts/Volvo-Novum-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Volvo-Novum-Medium";
  src: url("../public/fonts/Volvo-Novum-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Volvo-Novum-Bold";
  src: url("../public/fonts/Volvo-Novum-Bold.ttf") format("truetype");
}

.Volvo-Novum-Light {
  font-family: "Volvo-Novum-Light";
  -webkit-font-smoothing: antialiased;
}

.Volvo-Novum-Regular {
  font-family: "Volvo-Novum-Regular";
  -webkit-font-smoothing: antialiased;
}

.Volvo-Novum-Medium {
  font-family: "Volvo-Novum-Medium";
  -webkit-font-smoothing: antialiased;
}

.Volvo-Novum-Bold {
  font-family: "Volvo-Novum-Bold";
  -webkit-font-smoothing: antialiased;
}


/*  Components  */
.fd {
  color: #141414;
}
  @media screen and (max-width: 768px) {
    .hero {
        /* padding-top: 60px; */
        margin-bottom: -165px;
    }
  }
  .hero__row {
    padding: 64px 80px 176px 80px;
    min-height: 584px;
    justify-content: center;
    -webkit-box-pack: center;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-grow: 0;
    -webkit-box-flex: 0;
    flex-shrink: 1;
    flex-basis: auto;
    box-sizing: border-box;
    align-self: stretch;
    margin: 0;
  }
  @media screen and (min-width: 1600px) {
    .hero__row {
      height: 35vw;
    }
  }
  @media screen and (max-width: 768px) {
    .hero__row {
      padding: 10px 10px 10px 10px;
      min-height: 525px;
    }
  }
  .hero__back {
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .hero__back__img {
    min-height: 1px;
    height: 100%;
    width: 100%;
    display: block;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  .hero__back__img img {
    object-fit: contain;
    object-position: 0 100%;
    opacity: 1;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 768px) {
    .hero__back__img img {
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      transition-delay: initial;
      transition-property: opacity;
    }
  }
  .hero__texto {
    text-align: center;
    max-width: 100%;
    align-self: baseline;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-basis: auto;
    flex-shrink: 1;
  }
  @media screen and (max-width: 768px) {
    .hero__texto {
      pointer-events: auto;
      flex-grow: 0;
      -webkit-box-flex: 0;
    }
  }
  .hero__texto__espacio {
    width: 8px;
    align-items: stretch;
    -webkit-box-align: stretch;
    flex-basis: 8px;
    flex-shrink: 0;
    flex-wrap: nowrap;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .hero__texto__espacio {
      flex-grow: 0;
      -webkit-box-flex: 0;
      pointer-events: auto;
    }
  }
  .hero__texto__supTitulo {
    font-style: normal;
    line-height: 1.4;
    font-weight: 500;
    font-size: 1.25rem;
    white-space: pre-line;
    align-self: center;
    -ms-grid-row-align: center;
    max-width: 610px;
    margin: 0;
    color: #ffffff;
  }
  .hero__texto__titulo {
    letter-spacing: 0.02em;
    line-height: 1;
    font-size: 4.5rem;
    font-weight: 400;
    white-space: pre-line;
    align-self: center;
    -ms-grid-row-align: center;
    max-width: 610px;
    margin: 0;
    color: #ffffff;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .hero__texto__titulo {
      font-size: 2.5rem;
    }
  }
  .hero__texto__cbtn {
    margin-top: 12px;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-box-pack: center;
    align-self: stretch;
    -ms-grid-row-align: stretch;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    -webkit-box-flex: 0;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  @media screen and (max-width: 768px) {
    .hero__texto__cbtn {
      display: none;
    }
  }
  .hero__texto__cbtn__uno__link {
    outline-offset: 0.25em;
    outline-color: #ffffff;
    stroke: #ffffff;
    text-transform: uppercase;
    line-height: 1.375rem;
    padding: 12px 0;
    font-size: 0.875rem;
    text-decoration: none;
    letter-spacing: 0.02em;
    font-weight: 500;
    color: #ffffff;
    box-sizing: border-box;
    -webkit-box-direction: normal;
    text-align: center;
  }
  .hero__texto__cbtn__uno__link:hover {
    outline-color: #a3a3a3;
    stroke: #a3a3a3;
    color: #a3a3a3;
    outline-offset: 0.25em;
    text-decoration: none;
  }
  .hero__texto__cbtn__uno__link:hover .flecha {
    outline-color: #a3a3a3;
    stroke: #a3a3a3;
    color: #a3a3a3;
  }
  .hero__texto__cbtn__uno__link .flecha {
    white-space: nowrap;
    display: inline;
    stroke: #ffffff;
    text-transform: uppercase;
    line-height: 1.375rem;
    font-size: 0.875rem;
    letter-spacing: 0.02em;
    font-weight: 500;
    color: #ffffff;
    -webkit-box-direction: normal;
    text-align: center;
  }
  .hero__texto__cbtn__uno__link .flecha .img {
    margin-left: 0.55em;
    top: 1px;
    position: relative;
    vertical-align: baseline;
  }
  .hero__clink {
    display: none;
  }

  
  /*  Partials  */
.menu {
    background-color: #fafafa;
    padding: 18px 12px 18px 0;
    max-height: 64px;
    position: fixed;
    top: 0;
    z-index: 2;
  }
  .menu__row {
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .menu__row .fondo-menu-abierto {
    z-index: 1000;
    opacity: 1;
    width: 70%;
    max-height: 100vh;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    display: block;
    visibility: visible;
    left: 0;
  }
  .menu__nombre-dealer {
    width: max-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .menu__nombre-dealer__link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: transparent;
    margin-left: 16px;
    padding: 0 8px;
}
  .menu__nombre-dealer__link:hover {
    color: transparent;
    text-decoration: none;
  }
  .menu__nombre-dealer__logo {
    width: 110px;
    height: 10px;
  }
  .menu__nombre-dealer__span {
    font-size: 1.2rem;
    padding: 0 8px;
  }
  .menu__nombre-dealer__nombre {
    color: #707070;
    width: max-content;
    display: inline-block;
    margin: 0;
    line-height: 1.125;
    font-size: 1.2rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  
  /*  Footer  */
  .footer {
    font-size: 0.75rem;
    line-height: 1.6666666667;
    letter-spacing: 0.02em;
    font-weight: 300;
    margin: 0;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    justify-content: center;
    -webkit-box-pack: center;
    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    -webkit-box-align: center;
    background-color: #fafafa;
  }
  .footer__contenedor {
    width: 100%;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    justify-content: center;
    -webkit-box-pack: center;
    align-items: center;
    -webkit-box-align: center;
    background-color: #fafafa;
    padding: 0 24px 0 24px;
    letter-spacing: unset;
    text-transform: unset;
  }
  .footer__contenedor__listaopciones {
    flex-wrap: wrap;
    justify-content: center;
    -webkit-box-pack: center;
    letter-spacing: unset;
    text-transform: unset;
    -webkit-box-direction: normal;
  }
  @media screen and (max-width: 768px) {
    .footer__contenedor__listaopciones {
      -webkit-box-lines: multiple;
      justify-content: center;
      -webkit-box-pack: center;
    }
  }
  .footer__contenedor__listaopciones__opcion {
    display: inline;
    box-sizing: border-box;
    letter-spacing: unset;
    text-transform: unset;
  }
  .footer__contenedor__listaopciones__opcion__link {
    background: unset;
    border-bottom: unset;
    border-left: unset;
    border-right: unset;
    border-top: unset;
    border: unset;
    margin-top: 4px;
    box-sizing: border-box;
    text-decoration: none;
    transition: color 150ms ease;
    -webkit-transition: color 150ms ease;
    -moz-transition: color 150ms ease;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .footer__contenedor__listaopciones__opcion__link:hover {
    color: #1c6bba;
  }
  .footer__contenedor__copyright {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .footer__contenedor__copyright {
      padding-top: 8px;
    }
  }
  
  .input_volvo {
    height: 3rem;
    border-radius: .25rem;
    background-color: #dcdcdc;
    outline: none;
  }

  .input_volvo:focus {
    background-color: #dcdcdc;
    border: 1px solid #dadada;
    box-shadow: none !important;
  }
  
  .btn-primary {
    border-color: #151515;
    background: #151515;
    color: #ffffff;
    padding: 12px 32px;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.02em;
    justify-content: center;
    transition: border-color 0.3s, background 0.3s;
  }
  
  .btn-primary:hover {
    border-color: #000000;
    background: #000000;
  }
  
  .justifiqued {
    text-align: justify;
  }

  .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active, .btn-primary:focus {
    background-color: #131313 !important;
    border-color: #131313 !important;
    box-shadow: none !important;
  }