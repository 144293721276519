:root {
  --hero-transform-default: translate(-50%, -150%);
  --hero-transform-1024: translate(-43%, -125%);
  --hero-transform-1260: translate(-45%, -140%);
  --hero-transform-1280: translate(-40%, -140%);
  --hero-transform-1366: translate(-45%, -140%);
  --hero-transform-1440: translate(-50%, -130%);
  --hero-max-width-default: 1100px;
  --hero-max-width-1024: 825px;
  --hero-max-width-1060: 825px;
  --hero-max-width-1280: 1000px;
  --hero-max-width-1366: 950px;
  --hero-max-width-1440: 1050px;
  --hero-content-color: #000;
  --hero-form-width: 40%;
  --hero-row-min-height: 645px;
  --hero-back-img-height: 80%;
  --hero-back-img-width: 100%;
  --hero-back-img-width-1024: 180%;
  --hero-back-img-width-1060: 150%;
  --hero-back-img-width-1440: 155%;
  --hero-back-img-left-1024: -20%;
  --hero-back-img-left-1060: -12%;
  --hero-back-img-left-1440: -14%;
  --hero-back-img-height-1024: 120%;
  --hero-back-img-height-1060: 102%;
  --hero-back-img-height-1440: 105%;
  --font-size-h4: 1.1rem;
  --font-size-h2: 1.5rem;
  --font-size-h2-1024: 2rem;
  --font-size-h2-1366: 1.8rem;
  --font-size-h2-1440: 2.2rem;
}

.hero {
  position: relative;
  /* height: 100vh; */
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  padding-top: 64px;
}

.hero__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: var(--hero-transform-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: var(--hero-max-width-default);
  color: var(--hero-content-color);
}

.hero__text {
  max-width: 50%;
  border-left: 1px solid #151515;
  text-align: left;
  padding-left: 15px;
  margin-top: -170px;
}

.hero__form {
  width: var(--hero-form-width);
}

.hero__form h2,
.hero__form p {
  text-align: left;
}

.hero__row {
  padding: 0;
  min-height: var(--hero-row-min-height);
}

.hero__back__img {
  min-height: 0;
  height: var(--hero-back-img-height);
  width: var(--hero-back-img-width);
  display: block;
  flex-direction: row;
}

.hero__back__img img {
  object-fit: contain;
  object-position: 0 100%;
  opacity: 1;
  display: block;
  position: absolute;
  /* background-color: #f1f1f1; */
}

.h4,
h4 {
  font-size: var(--font-size-h4);
}

.h2,
h2 {
  font-size: var(--font-size-h2);
}

@media (min-width: 1024px) and (max-width: 1024px) and (min-height: 800px) and (max-height: 800px) {
  .hero__content {
    transform: var(--hero-transform-1024);
    max-width: var(--hero-max-width-1024);
  }

  .hero__back__img img {
    width: var(--hero-back-img-width-1024);
    left: var(--hero-back-img-left-1024);
    height: var(--hero-back-img-height-1024);
  }

  .h2,
  h2 {
    font-size: var(--font-size-h2-1024);
  }
}

@media (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 950px) {
  .hero__content {
    transform: var(--hero-transform-1280);
    max-width: var(--hero-max-width-1280);
  }

  .hero__back__img img {
    width: var(--hero-back-img-width-1440);
    left: var(--hero-back-img-left-1440);
    height: var(--hero-back-img-height-1440);
  }
}

@media (min-width: 1366px) and (max-width: 1366px) {
  .hero__content {
    transform: var(--hero-transform-1366);
    max-width: var(--hero-max-width-1366);
  }

  .hero__back__img img {
    width: var(--hero-back-img-width-1440);
    left: var(--hero-back-img-left-1440);
    height: var(--hero-back-img-height-1440);
  }

  .h2,
  h2 {
    font-size: var(--font-size-h2-1366);
  }
}

@media (min-width: 1400px) and (max-width: 1440px) and (min-height: 900px) and (max-height: 900px) {
  .hero__content {
    transform: var(--hero-transform-1440);
    max-width: var(--hero-max-width-1440);
  }

  .hero__back__img img {
    width: var(--hero-back-img-width-1024);
    left: var(--hero-back-img-left-1024);
    height: var(--hero-back-img-height-1024);
  }

  .h2,
  h2 {
    font-size: var(--font-size-h2-1440);
  }
}


@media (min-width: 1260px) and (max-height: 720px) {
  .hero__content {
    transform: var(--hero-transform-1260);
    max-width: var(--hero-max-width-1060);
  }

  .hero__back__img img {
    width: var(--hero-back-img-width-1060);
    left: var(--hero-back-img-left-1060);
    height: var(--hero-back-img-height-1060);
  }
}

/* Ajustes para versão mobile */
@media (max-width: 767px) {
  .hero__content {
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding: 20px;
  }

  .hero__back__img img {
    width: 200%;
    left: -10%;
    height: 55%;
  }

  .hero__text {
    max-width: 100%;
    margin-top: -375px;
    border-left: none;
    text-align: center;
    padding-left: 0;
    margin-bottom: 20px;
  }

  .hero__form {
    width: 100%;
    margin-top: 55%;
  }

  .hero__form h2,
  .hero__form p {
    text-align: center;
  }
}