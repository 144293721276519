/* WhatsAppButton.css */
.whatsapp-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: bottom 0.3s;
  }
  
  .whatsapp-button img {
    width: 40px;
    height: 40px;
  }
  
  /* Estilos para mobile */
  @media screen and (max-width: 768px) {
    .whatsapp-button {
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      border-radius: 0;
      justify-content: center;
    }
  
    .whatsapp-button img {
      width: 30px;
      height: 30px;
    }
  }
  